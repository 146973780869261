import BlocksContent from "@components/blocksContent";
import { Maybe, SanityCareer, SanityImageWithMeta } from "@graphql-types";
import { Container, GridContainer, MenuButtonGatsbyLink } from "@util/standard";
import React from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@util/constants";
import Image from "@components/shared/sub/image";

interface Props {
  careerBlock?: Maybe<SanityCareer>;
  noCareers?: boolean;
  noCareerContent?: any;
  defaultImage?: Maybe<SanityImageWithMeta> | undefined;
}

const ImageWrapper = styled.div`
  height: auto;
  width: 80%;
  margin: 40px auto;

  .gatsby-image-wrapper {
    height: 80%;
    img {
      object-fit: contain !important; // !important to override the element style
      height: 80%;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 200px;
  }
`;

const SanityCareerBlock = (props: Props) => {
  const { careerBlock, noCareers, noCareerContent, defaultImage } = props;

  return (
    <Container border width="80%" margin="20px auto 70px auto">
      {noCareers ? (
        <GridContainer gridTemplateColumns="1fr 1fr" mobileRepeat={1}>
          <ImageWrapper>
            {defaultImage && <Image data={defaultImage} width="100%" />}
          </ImageWrapper>
          <Container padding="80px 50px" mobilePadding="40px 40px 40px 40px">
            <h2>{noCareerContent?.careerMessage.title}</h2>
            <BlocksContent
              blocks={
                noCareerContent?.careerMessage.description.columnContent
              }
            />
          </Container>
        </GridContainer>
      ) : (
        <GridContainer gridTemplateColumns="1fr 1fr" mobileRepeat={1}>
          <ImageWrapper>
            {defaultImage && <Image data={defaultImage} width="100%" />}
          </ImageWrapper>
          {careerBlock && (
            <Container padding="80px 50px" mobilePadding="40px 40px 40px 40px">
              {careerBlock.jobTitle && <h2>{careerBlock.jobTitle}</h2>}
              <p>{careerBlock.description}</p>
              {careerBlock.link && (
                <MenuButtonGatsbyLink
                  to={!!careerBlock.link.url ? careerBlock.link.url : ""}
                  width="fit-content"
                  isPink
                  margin="0"
                  padding="10px 25px 10px 25px"
                >
                  {careerBlock.link.linktext}
                </MenuButtonGatsbyLink>
              )}
            </Container>
          )}
        </GridContainer>
      )}
    </Container>
  );
};

export default SanityCareerBlock;
